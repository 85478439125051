html, body {
  width:100%;
  height:100%;
  font-family: $font-family;
  font-size:14px;
  background-color:#f3f4f6;
}

body.noscroll {
  overflow: hidden;
}

.container {
  min-width: 1180px;
}

.fa, .fa-brands, .fa-duotone, .fa-light, .fa-regular, .fa-solid, .fa-thin, .fab, .fad, .fal, .far, .fas, .fat {
  line-height: inherit;
}

.leerlijn__level {
  width: 1rem;
  height: 1rem;
  border-radius: 30rem;
}
.modal {
  z-index:100;
}

textarea { resize: none !important; }

/******* SIDEBAR *******/
.menu { font-size:13px; }
.submenu {
  display:none;
  padding-left: 20px;
}
.menu-item {
  padding: 8px 0px;
  border-bottom: 1px solid #ccc;
  cursor:pointer;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  display: grid;
}
.menu-item-text {
  grid-column-start: 1;
  grid-column: span 5 / span 5;
}
.menu-item-icon {
  text-align: center;
}


/******* GRID *******/
/*
#datagrid table td:first-child {
    position: sticky;
    left: 0;
}*/


.cell-student {
  width: 250px;
  overflow:hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
}

/******* OVERLAY *******/
.overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(42, 42, 41, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s ease;
  z-index: 999;
}
.overlay__visible {
  visibility: visible;
  opacity: 1;
}


/******* NAKIJKEN *******/
table.antwoorden {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0px;
}
.nakijken-resultaat {
  font-size:16px;
  background-color: #fff;
  padding: 4px 15px;
  border-radius: 5px;
  display:none;
}
.nakijken-resultaat i {
  color:#f79d5d;
}
.nakijken-oefening {
  font-weight:600;
}
.nakijken-antwoorden {

}

.nakijken-antwoorden td {
  border-bottom:1px solid $color-grey-light-2;
  vertical-align: top;
  padding:5px 0px;
}

.nakijken-antwoorden label {
  margin-left: 6px;
}

.nakijken-zelfnakijken {
  font-size: 1.6rem;
  padding: 10px;
}


input[type=radio].goed, input[type=radio].fout {
  cursor:pointer;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  overflow: hidden;
  padding: 0;
  display:none;
}

input[type=radio].goed ~ label:before, input[type=radio].fout ~ label:before {
  font-family: FontAwesome;
  display: inline-block;
  content: "\f1db";
  letter-spacing: 10px;
  font-size: 1.2em;
  color: $color-grey-light-3;
  width: 1.4em;
}
input[type=radio].goed:checked ~ label:before, input[type=radio].fout:checked ~ label:before {
  font-size: 1.2em;
  letter-spacing: 5px;
}

input[type=radio].goed:checked ~ label:before {
  content: "\f058";
  color: $color-green;
}
input[type=radio].fout:checked ~ label:before {
  content: "\f057";
  color: $color-red;
}

.antwoord-combinatie .nummer {
  display:none;
}


.ongelezen-berichten {
  display:none;
  background-color:$color-red;
  border-radius:50%;
  color:$color-white;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  margin-left:4px;
}

.nakijken-notificatie {
  display:inline-block;
  color:$color-blue-1;
  opacity: 1;
  cursor:pointer;
}

select {
  border-radius: 5px !important;
}

.student-link:hover {
  text-decoration: underline;
}