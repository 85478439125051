html, body {
  width: 100%;
  height: 100%;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  background-color: #f3f4f6;
}

body.noscroll {
  overflow: hidden;
}

.container {
  min-width: 1180px;
}

.fa, .fa-brands, .fa-duotone, .fa-light, .fa-regular, .fa-solid, .fa-thin, .fab, .fad, .fal, .far, .fas, .fat {
  line-height: inherit;
}

.leerlijn__level {
  width: 1rem;
  height: 1rem;
  border-radius: 30rem;
}

.modal {
  z-index: 100;
}

textarea {
  resize: none !important;
}

/******* SIDEBAR *******/
.menu {
  font-size: 13px;
}

.submenu {
  display: none;
  padding-left: 20px;
}

.menu-item {
  padding: 8px 0px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  display: grid;
}

.menu-item-text {
  grid-column-start: 1;
  grid-column: span 5/span 5;
}

.menu-item-icon {
  text-align: center;
}

/******* GRID *******/
/*
#datagrid table td:first-child {
    position: sticky;
    left: 0;
}*/
.cell-student {
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/******* OVERLAY *******/
.overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(42, 42, 41, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s ease;
  z-index: 999;
}

.overlay__visible {
  visibility: visible;
  opacity: 1;
}

/******* NAKIJKEN *******/
table.antwoorden {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0px;
}

.nakijken-resultaat {
  font-size: 16px;
  background-color: #fff;
  padding: 4px 15px;
  border-radius: 5px;
  display: none;
}

.nakijken-resultaat i {
  color: #f79d5d;
}

.nakijken-oefening {
  font-weight: 600;
}

.nakijken-antwoorden td {
  border-bottom: 1px solid #bfbfbe;
  vertical-align: top;
  padding: 5px 0px;
}

.nakijken-antwoorden label {
  margin-left: 6px;
}

.nakijken-zelfnakijken {
  font-size: 1.6rem;
  padding: 10px;
}

input[type=radio].goed, input[type=radio].fout {
  cursor: pointer;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  overflow: hidden;
  padding: 0;
  display: none;
}

input[type=radio].goed ~ label:before, input[type=radio].fout ~ label:before {
  font-family: FontAwesome;
  display: inline-block;
  content: "\f1db";
  letter-spacing: 10px;
  font-size: 1.2em;
  color: #dddddd;
  width: 1.4em;
}

input[type=radio].goed:checked ~ label:before, input[type=radio].fout:checked ~ label:before {
  font-size: 1.2em;
  letter-spacing: 5px;
}

input[type=radio].goed:checked ~ label:before {
  content: "\f058";
  color: #94c01f;
}

input[type=radio].fout:checked ~ label:before {
  content: "\f057";
  color: #f15853;
}

.antwoord-combinatie .nummer {
  display: none;
}

.ongelezen-berichten {
  display: none;
  background-color: #f15853;
  border-radius: 50%;
  color: #fff;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  margin-left: 4px;
}

.nakijken-notificatie {
  display: inline-block;
  color: #009ede;
  opacity: 1;
  cursor: pointer;
}

select {
  border-radius: 5px !important;
}

.student-link:hover {
  text-decoration: underline;
}

#window1 {
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: auto;
  transform: translate(-50%, -50%);
  z-index: 9000;
  background-color: #eeeeee;
  max-width: 1500px;
  min-width: 1000px;
  border-radius: 1.5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  display: none;
  max-height: 95vh;
  overflow: hidden;
}

#window2 {
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: auto;
  transform: translate(-50%, -50%);
  z-index: 9000;
  background-color: #eeeeee;
  width: 60%;
  max-width: 1500px;
  min-width: 600px;
  border-radius: 1.5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  display: none;
  max-height: 95vh;
  overflow: hidden;
}

#window3 {
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: auto;
  transform: translate(-50%, -50%);
  z-index: 9000;
  background-color: #eeeeee;
  width: 60%;
  max-width: 1500px;
  min-width: 600px;
  border-radius: 1.5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  display: none;
  max-height: 95vh;
  overflow: hidden;
}

#window5 {
  position: fixed;
  left: 60%;
  top: 50%;
  transform-origin: top center;
  transform: translate(-10%, 0);
  z-index: 9000;
  background-color: #fff;
  width: 500px;
  border-radius: 1.5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  display: none;
}

[id^=closeButton] {
  color: #ffffff;
  cursor: pointer;
  position: relative;
  font-size: 24px;
}

.window__title {
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 20px;
  z-index: 10;
  background-color: #009ede;
  color: #fff;
  border-radius: 1.5rem 1.5rem 0 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
  touch-action: none;
  font-weight: 600;
  cursor: move;
  cursor: grab;
}
.window__title--yellow {
  background-color: #ffe94c;
  color: #2a2a29;
}
.window__title--yellow b {
  color: #2a2a29;
}
.window__title--orange {
  background-color: #eab435;
  color: #fff;
}
.window__title--blue {
  background-color: #023e7c;
  color: #fff;
}

.window--subhead {
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.window--subhead--gray {
  background-color: #eeeeee;
}
.window--subhead--yellow {
  background-color: #fff7bf;
}
.window--subhead--orange {
  padding: 10px;
  background-color: #eab435;
  color: #2a2a29;
}
.window--subhead--center {
  justify-content: center;
}

.window__footer {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
}
.window__footer__button {
  cursor: pointer;
  border: 1px solid #e9e9e9;
  padding: 4px 10px;
  font-weight: 600;
  border-radius: 5px;
}
.window__footer__button--white {
  background-color: #fff;
}
.window__footer__button:hover {
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
}
.window__footer--gray {
  background-color: #eeeeee;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}
.window__footer__button i {
  color: #009ede;
}

.windowActive {
  z-index: 10000;
}

.mainWindow {
  border: 20px solid white;
  overflow: hidden;
  border-radius: 0 0 1.5rem 1.5rem;
}

.nakijken-antwoorden {
  overflow-y: auto;
  max-height: 450px;
  padding-right: 10px;
}

.small {
  width: 600px;
}

.large {
  width: 1000px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.exercise-panel {
  font-size: 14px;
  position: relative;
}
.exercise-panel__top {
  padding: 0 0 2rem 0;
  border-bottom: 1px solid #bfbfbe;
}
.exercise-panel__middle {
  padding: 0.5rem 0;
  border-bottom: 1px solid #bfbfbe;
}
.exercise-panel__bottom {
  position: relative;
  padding: 1rem 0 0;
}
.exercise-panel__bottom__disabler {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.001;
  z-index: 10000;
  display: none;
}
.exercise-panel__title {
  background-color: #023e7c;
  color: #fff;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 0.5rem 2.8rem 0.9rem 6.5rem;
  border-radius: 30rem;
  width: max-content;
  position: relative;
  margin-left: 5px;
}
.exercise-panel__title--sub {
  display: inline-block;
  background-color: #009ede;
  border-radius: 30rem;
  padding: 0.5rem 1.9rem 0.9rem;
  position: absolute;
  left: -3px;
  top: 0;
}
.exercise-panel .panel-wrapper {
  padding: 0 3rem 0 0;
}
.exercise-panel__feedback {
  padding: 2rem 0 0 0;
}

.feedback-button {
  display: none;
  background-color: #fff7bf;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  display: inline-block;
  cursor: pointer;
}

.feedback-container {
  display: none;
  position: relative;
  background-color: #fff7bf;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
}

.exercise-panel__feedback .orange {
  color: #eab435;
  margin-right: 4px;
}

.close-feedback {
  background-color: #fff;
  color: #eab435;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 32px;
  position: absolute;
  right: 5px;
  bottom: 5px;
  cursor: pointer;
}

.leerlijn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.leerlijn__box {
  display: flex;
  align-items: center;
}
.leerlijn__box .btn--primary {
  margin-right: 1rem;
}
.leerlijn__icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
.leerlijn__num {
  font-size: 26px;
  font-weight: 700;
  margin-right: 2rem;
  color: #023e7c;
}
.leerlijn__level {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 30rem;
}
.leerlijn__level:not(:last-child) {
  margin-right: 0.5rem;
}
.leerlijn__level--1 {
  background-color: #fff;
  border: 1px solid rgba(42, 42, 41, 0.3);
}
.leerlijn__level--2 {
  background-color: #eab435;
}
.leerlijn__level--3 {
  background-color: #009ede;
}
.leerlijn__level--hidden {
  visibility: hidden;
}

.context-item {
  padding-right: 20px;
  margin-bottom: 30px;
}

.context-item video {
  width: 100%;
  height: auto;
  border: 1px solid #bfbfbe;
  border-radius: 1.3rem;
}

.context-text {
  padding: 20px;
  padding-right: 40px;
}

.context-header-image {
  border-radius: 1.3rem;
  width: 100%;
}

.context-item.soort-1, .context-item.soort-2 {
  margin-right: 20px;
  border-radius: 1.3rem;
}

.context-item.soort-1 {
  background-color: #81d0f5;
}

.context-item.soort-2 {
  background-color: #fff381;
}

.context-item.soort-1 .heading-secondary, .context-item.soort-2 .heading-secondary {
  font-family: "Zilla Slab", serif;
  font-weight: 700;
}

table.exercises {
  max-width: 800px;
  margin-top: 10px;
  width: 100%;
  table-layout: fixed;
  border-spacing: 0px;
}

table.exercises td {
  height: 50px;
  line-height: 35px;
}

td.vraagnummer {
  width: 30px;
  vertical-align: top;
}

table.exercises table {
  border-collapse: collapse !important;
}

/* STELLINGEN */
.stelling {
  display: inline-block;
  min-width: 20px;
  cursor: pointer;
  border-radius: 2rem;
  padding: 2px 4px;
}
.stelling.highlight {
  background-color: #e9e9e9;
}
.stelling.right {
  float: right;
}
.stelling span {
  border: 1px solid #bfbfbe;
  padding: 4px 15px;
  border-radius: 1.5rem;
  font-weight: 600;
  background-color: #fff;
}
.stelling span:hover {
  background-color: #009ede;
  border: 1px solid #009ede;
  color: #fff;
}
.stelling span.active {
  background-color: #009ede;
  border: 1px solid #009ede;
  color: #fff;
}
.stelling span.goed {
  background-color: #94c01f;
  border-color: #94c01f;
}
.stelling span.fout {
  background-color: #f15853;
  border-color: #f15853;
}

table.exercises input {
  width: auto;
  padding: 5px;
}

table.exercises input.highlight {
  background-color: #e9e9e9;
}

table.exercises input.goed {
  color: #94c01f;
  font-weight: 600;
}

table.exercises input.fout {
  color: #f15853;
  font-weight: 600;
}

table.exercises input.klein {
  width: 100px;
}

table.exercises input.middel {
  width: 200px;
}

table.exercises input.groot {
  width: 200px;
}

table.exercises .textarea {
  background-image: url(/img/bg-dots.png);
  color: #009ede;
  padding: 0px 10px;
  line-height: 45px;
}

table.exercises .textarea.highlight {
  background-color: #e9e9e9;
}

table.exercises .textarea.goed {
  color: #94c01f;
  font-weight: 600;
}

table.exercises .textarea.fout {
  color: #f15853;
  font-weight: 600;
}

input.groot, input.klein, input.middel, input.veld-100 {
  border: 0;
  text-align: center;
  border-bottom: 2px dotted #73c9ed;
  padding: 0px 5px;
  border-radius: 0;
  color: #009ede;
  font-size: 1.2rem;
}

input.groot {
  text-align: left;
}

table.exercises input.veld-100 {
  text-align: left;
  width: 95%;
}

.combinatie-holder {
  display: flex;
  flex-flow: row wrap;
  gap: 10px 20px;
}

.combinatie {
  width: 400px;
  height: 45px;
  border: 1px solid #e9e9e9;
  border-radius: 0.6rem;
  text-align: left;
  padding: 5px 10px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
  z-index: 100;
}

.combinatie .letter {
  background-color: #009ede;
  color: #fff;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  margin-right: 10px;
  display: inline-block;
}

.combinatie.movable {
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
  z-index: 1000;
}

.combinatie.goed {
  background-color: #94c01f;
  border-color: #94c01f;
}

.combinatie.goed .letter, .combinatie.fout .letter {
  background-color: transparent;
}

.combinatie.fout {
  background-color: #f15853;
  border-color: #f15853;
}

.combinatie-antwoord.highlight {
  border: 1px solid #bfbfbe;
  background-color: #e9e9e9;
}

.drop-zone {
  background-color: #bfbfbe;
  padding: 20px;
  margin-top: 20px;
}

.drop-zone table {
  width: auto;
  margin: auto;
}

.combinatie-antwoord {
  width: 100%;
  border: 1px solid #e9e9e9;
  border-radius: 0.6rem;
  text-align: left;
  padding: 5px 20px;
  height: 45px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
}

.combinatie-antwoord .nummer {
  color: #009ede;
}

.drop-container {
  min-width: 300px;
  border: 1px solid #e9e9e9;
  border-radius: 0.6rem;
  text-align: left;
  font-weight: 600;
  height: 45px;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
}

.combinatie-container {
  background-color: #eeeeee;
  border-radius: 0.6rem;
  width: 300px;
  height: 45px;
}

.combinatie-tr td {
  padding-right: 2px;
}

.combinatie-tr {
  border: 2px dashed transparent;
}

.combinatie-tr.highlight {
  border-color: #eab435;
}

table.schema {
  width: 100%;
  table-layout: auto;
}

.schema__titel {
  font-weight: bold;
  color: #009ede;
}

td.meerkeuze__keuze {
  width: 40px;
}

table.meerkeuze td {
  height: auto;
}

.meerkeuze_container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.meerkeuze_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark, .checkmark-abc {
  position: absolute;
  top: -8px;
  left: 0;
  font-size: 18px;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 26px;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 50%;
}

.meerkeuze_container:hover input ~ .checkmark, .meerkeuze_container:hover input ~ .checkmark-abc {
  border-color: #b2e2f5;
  background-color: #b2e2f5;
}

.meerkeuze_container input:checked ~ .checkmark, .meerkeuze_container input:checked ~ .checkmark-abc {
  background-color: #009ede;
  border: 1px solid #009ede;
  color: #fff;
}

.meerkeuze_container input:checked ~ .checkmark.goed {
  background-color: #94c01f;
}

.meerkeuze_container input:checked ~ .checkmark.fout {
  background-color: #f15853;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.meerkeuze_container input:checked ~ .checkmark:after {
  display: block;
}

.meerkeuze_container .checkmark:after {
  left: 8px;
  top: 5px;
  width: 8px;
  height: 12px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.woordzoeker-woorden {
  display: flex;
  flex-wrap: wrap;
}

.woordzoeker-woord {
  flex-grow: 1;
  width: 33%;
  line-height: 30px;
  color: #2a2a29;
}

.woordzoeker-woord.found {
  color: #00b394;
  text-decoration: line-through;
}

.woordzoeker-vragen {
  margin-bottom: 10px;
  display: grid;
  grid-column-gap: 60px;
  grid-template-columns: auto auto;
}

.woordzoeker-vragen div {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 10px;
}

.woordzoeker-holder {
  background-color: #eeeeee;
  padding: 20px;
  margin-top: 20px;
}

table.woordzoeker {
  width: auto;
  margin: auto;
}

table.woordzoeker td {
  text-transform: uppercase;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #00b394;
  background-color: #fff;
  border-radius: 0.5rem;
}

table.woordzoeker td.active {
  background-color: #00b394;
  color: #fff;
}

table.woordzoeker td.correct {
  background-color: #00b394;
  color: #fff;
}

table.woordzoeker td.correct.goed {
  background-color: #94c01f;
}

table.woordzoeker td, table.woordzoeker td div {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

table.woordzoeker td div {
  display: inline;
  padding: 2px 8px;
  cursor: pointer;
}

*[contenteditable] {
  display: inline-block;
  width: 100%;
}

.begrippennet {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.begrippennet td {
  padding: 10px 40px;
}

.input_begrippennet {
  width: 150px !important;
  padding: 10px !important;
  width: 150px !important;
  border-color: #dddddd;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
}

.ballon_line {
  border: 1px dotted;
  border-radius: 90px;
  color: #2a2a29;
}

#web_midden {
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-color: #bfbfbe;
}

#web_midden, .input_begrippennet {
  text-align: center;
}

#begrippennet_container {
  position: relative;
}

connection {
  z-index: 0;
}

.disabler {
  background-color: #000;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.01;
}