.exercise-panel {
    font-size:14px;
    position: relative;

    &__top {
        padding: 0 0 2rem 0;
        border-bottom: 1px solid $color-grey-light-2;
    }

    &__middle {
        padding: 0.5rem 0;
        border-bottom: 1px solid $color-grey-light-2;
    }

    &__bottom {
        position: relative;
        padding: 1rem 0 0;

        &__disabler {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: black;
            opacity: 0.001;
            z-index:10000;
            display:none;
        }
    }

    &__title {
        background-color: $color-blue-3;
        color: $color-white;
        font-family: $font-display;
        font-size: 2rem;
        font-weight: 600;
        text-transform: uppercase;
        white-space: nowrap;
        padding: 0.5rem 2.8rem 0.9rem 6.5rem;
        border-radius: 30rem;
        width: max-content;
        position: relative;
        margin-left: 5px;

        &--sub {
            display: inline-block;
            background-color: $color-blue-1;
            border-radius: 30rem;
            padding: 0.5rem 1.9rem 0.9rem;
            position: absolute;
            left: -3px;
            top: 0;
        }
    }

    .panel-wrapper {
        padding: 0 3rem 0 0;
    }

    &__feedback {
        padding:2rem 0 0 0;

    }
}

.feedback-button {
    display:none;
    background-color:$color-yellow-2;
    padding:1rem 1.5rem;
    border-radius: 1rem;
    display:inline-block;
    cursor:pointer;
}
.feedback-container {
    display:none;
    position:relative;
    background-color:$color-yellow-2;
    padding:1rem 1.5rem;
    border-radius: 1rem;
}
.exercise-panel__feedback .orange {
    color:$color-orange;
    margin-right:4px;
}
.close-feedback {
    background-color:$color-white;
    color:$color-orange;
    border-radius:50%;
    width:30px;
    height:30px;
    text-align:center;
    line-height:32px;
    position:absolute;
    right:5px;
    bottom:5px;
    cursor:pointer;
}


.leerlijn {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__box {
        display: flex;
        align-items: center;

        .btn--primary {
            margin-right: 1rem;
        }
    }

    &__box:last-child {

    }
    &__icon {
        width: 24px;
        height: 24px;
        margin-right: 12px;
    }

    &__num {
        font-size: 26px;
        font-weight: 700;
        margin-right: 2rem;
        color:$color-blue-3;
    }

    &__level {
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 30rem;

        &:not(:last-child) {
            margin-right: 0.5rem;
        }

        &--1 {
            background-color: $color-white;
            border: 1px solid rgba($color-grey-dark-2, 0.3);
        }

        &--2 {
            background-color: $color-orange;
        }

        &--3 {
            background-color: $color-blue-1;
        }

        &--hidden {
            visibility: hidden;
        }
    }
}

.context-item {
    padding-right:20px;
    margin-bottom:30px;
}
.context-item video {
    width: 100%;
    height: auto;
    border:1px solid $color-grey-light-2;
    border-radius:1.3rem;
}
.context-text {
    padding:20px;
    padding-right:40px;
}
.context-header-image {
    border-radius:1.3rem;
    width:100%;
}
.context-item.soort-1, .context-item.soort-2 {
    margin-right:20px;
    border-radius:1.3rem;
}
.context-item.soort-1 {
    background-color: #81d0f5;
}

.context-item.soort-2 {
    background-color: #fff381;
}

.context-item.soort-1 .heading-secondary, .context-item.soort-2 .heading-secondary {
    font-family: 'Zilla Slab', serif;
    font-weight:700;
}


table.exercises {
    max-width:800px;
    margin-top:10px;
    width: 100%;
    table-layout: fixed;
    border-spacing: 0px;
}
table.exercises td {
    height:50px;
    line-height:35px;
}
td.vraagnummer {
    width: 30px;
    vertical-align: top;
}

table.exercises table {
    border-collapse: collapse !important;
}


/* STELLINGEN */

.stelling {
    display: inline-block;
    min-width: 20px;
    cursor: pointer;
    border-radius: 2rem;
    padding: 2px 4px;

    &.highlight {
        background-color:$color-grey-light-4;
    }

    &.right {
        float: right;
    }

    span {
        border: 1px solid $color-grey-light-2;
        padding: 4px 15px;
        border-radius: 1.5rem;
        font-weight: 600;
        background-color:$color-white;
    }

    span:hover {
        background-color: $color-blue-1;
        border: 1px solid $color-blue-1;
        color: $color-white;
    }

    span.active {
        background-color: $color-blue-1;
        border: 1px solid $color-blue-1;
        color: $color-white;
    }

    span.goed {
        background-color:$color-green;
        border-color:$color-green;
    }
    span.fout {
        background-color:$color-red;
        border-color:$color-red;
    }
}


table.exercises input {
    width:auto;
    padding:5px;
}
table.exercises input.highlight {
    background-color:$color-grey-light-4;
}


table.exercises input.goed {
    color:$color-green;
    font-weight:600;
}
table.exercises input.fout {
    color:$color-red;
    font-weight:600;
}

table.exercises input.klein {
    width:100px;
}
table.exercises input.middel {
    width:200px;
}
table.exercises input.groot {
    width:200px;
}

table.exercises .textarea {
    background-image: url(/img/bg-dots.png);
    color:$color-exercise-input ;
    padding: 0px 10px;
    line-height:45px;
}
table.exercises .textarea.highlight {
    background-color:$color-grey-light-4
}
table.exercises .textarea.goed {
    color:$color-green;
    font-weight:600;
}
table.exercises .textarea.fout {
    color:$color-red;
    font-weight:600;
}


input.groot, input.klein, input.middel, input.veld-100 {
    border:0;
    text-align:center;
    border-bottom:2px dotted #73c9ed;
    padding: 0px 5px;
    border-radius:0;
    color:$color-exercise-input;
    font-size: 1.2rem;
}
input.groot {
    text-align:left;
}
table.exercises input.veld-100 {
    text-align:left;
    width:95%;
}


//COMBINATIES MAKEN
.combinatie-holder {
    display: flex;
    flex-flow: row wrap;
    gap: 10px 20px;


}
.combinatie {
    width: 400px;
    height:45px;
    border: 1px solid #e9e9e9;
    border-radius: 0.6rem;
    text-align: left;
    padding: 5px 10px;
    font-weight: 600;
    cursor:pointer;
    user-select:none;
    background-color:$color-white;
    z-index:100;
}
.combinatie .letter {
    background-color:$color-blue-1;
    color: $color-white;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    margin-right: 10px;
    display: inline-block;
}
.combinatie.movable {
    box-shadow:$box-shadow;
    z-index:1000;
}

.combinatie.goed {
    background-color:$color-green;
    border-color:$color-green;
}
.combinatie.goed .letter, .combinatie.fout .letter {
    background-color:transparent;
}
.combinatie.fout {
    background-color:$color-red;
    border-color:$color-red;
}
.combinatie-antwoord.highlight {
    border:1px solid $color-grey-light-2;
    background-color:$color-grey-light-4;
}

.drop-zone {
    background-color:$color-grey-light-2;
    padding:20px;
    margin-top:20px;
}
.drop-zone table {
    width:auto;
    margin:auto;
}

.combinatie-antwoord {
    width: 100%;
    border: 1px solid #e9e9e9;
    border-radius: 0.6rem;
    text-align: left;
    padding: 5px 20px;
    height: 45px;
    font-weight: 600;
    cursor:pointer;
    user-select:none;
    background-color:$color-white;
    box-shadow:$box-shadow;
}
.combinatie-antwoord .nummer {
    color:$color-blue-1;
}
.drop-container {
    min-width: 300px;
    border: 1px solid #e9e9e9;
    border-radius: 0.6rem;
    text-align: left;
    font-weight: 600;
    height:45px;
    cursor:pointer;
    user-select:none;
    background-color:$color-white;
}

.combinatie-container {
    background-color:$color-grey-light-1;
    border-radius: 0.6rem;
    width: 300px;
    height:45px;

}
.combinatie-tr td {
    padding-right: 2px;
}
.combinatie-tr {
    border:2px dashed transparent;
}
.combinatie-tr.highlight {
    border-color: $color-orange;
}

//SCHEMA'S
table.schema {
    width:100%;
    table-layout: auto;
}
.schema__titel {
    font-weight:bold;
    color:$color-blue-1;
}
table.schema td {

}


//MEERKEUZE

td.meerkeuze__keuze {
    width:40px;
}

table.meerkeuze td {
    height:auto;
}

.meerkeuze_container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.meerkeuze_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark, .checkmark-abc {
    position: absolute;
    top: -8px;
    left: 0;
    font-size: 18px;
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 26px;
    background-color: $color-white;
    border:1px solid $color-grey-light-3;
    border-radius:50%;
}

.meerkeuze_container:hover input ~ .checkmark, , .meerkeuze_container:hover input ~ .checkmark-abc {
    border-color: $color-blue-2;
    background-color: $color-blue-2;
}

.meerkeuze_container input:checked ~ .checkmark, .meerkeuze_container input:checked ~ .checkmark-abc {
    background-color: $color-blue-1;
    border:1px solid $color-blue-1;
    color:$color-white;
}
.meerkeuze_container input:checked ~ .checkmark.goed {
    background-color:$color-green;
}
.meerkeuze_container input:checked ~ .checkmark.fout {
    background-color:$color-red;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.meerkeuze_container input:checked ~ .checkmark:after {
    display: block;
}

.meerkeuze_container .checkmark:after {
    left: 8px;
    top: 5px;
    width: 8px;
    height: 12px;
    border: solid $color-white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.woordzoeker-container {

}

.woordzoeker-woorden {
    display: flex;
    flex-wrap: wrap;
}

.woordzoeker-woord {
    flex-grow: 1;
    width: 33%;
    line-height: 30px;
    color:$color-black-1;
}
.woordzoeker-woord.found {
    color:#00b394;
    text-decoration: line-through;
}

.woordzoeker-vragen {
    margin-bottom: 10px;
    display: grid;
    grid-column-gap: 60px;
    grid-template-columns: auto auto;
}

.woordzoeker-vragen div {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 10px;
}

.woordzoeker-holder {
    background-color:$color-grey-light-1;
    padding:20px;
    margin-top:20px;
}
table.woordzoeker {
    width:auto;
    margin:auto;
}
table.woordzoeker td {
    text-transform: uppercase;
    width:40px;
    height:40px;
    line-height:40px;
    text-align:center;
    color:#00b394;
    background-color:$color-white;
    border-radius:0.5rem;
}

table.woordzoeker td.active {
    background-color:#00b394;
    color:$color-white;
}
table.woordzoeker td.correct {
    background-color:#00b394;
    color:$color-white;
}
table.woordzoeker td.correct.goed {
    background-color:$color-green;
}

table.woordzoeker td, table.woordzoeker td div {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

table.woordzoeker td div {
    display: inline;
    padding: 2px 8px;
    cursor:pointer;
}

*[contenteditable] {
    display: inline-block;
    width: 100%;
}

.begrippennet {
    max-width:600px;
    margin-left: auto;
    margin-right: auto;
}

.begrippennet td {
    padding:10px 40px;
}

.input_begrippennet {
    width: 150px !important;
    padding:10px !important;
    width:150px !important;
    border-color: $color-grey-light-3;
    box-shadow: $box-shadow-dark;
}

.ballon_line {
    border: 1px dotted;
    border-radius: 90px;
    color: $color-grey-dark-2;
}

#web_midden {
    box-shadow: $box-shadow-dark;
    border-color: $color-grey-light-2;
}

#web_midden, .input_begrippennet {
    text-align:center;
}

#begrippennet_container {
    position:relative;
}

connection {
    z-index:0;
}

.disabler {
    background-color: #000;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.01;
}