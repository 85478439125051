#window1 {
    position: fixed;
    top: 50%;
    left: 50%;
    overflow:auto;
    transform: translate(-50%, -50%);
	z-index: 9000;
	background-color: #eeeeee;
    max-width:1500px;
    min-width:1000px;
    border-radius: 1.5rem;
    box-shadow: 0 1rem 2rem rgb(0 0 0 / 10%);
	display: none;
    max-height:95vh;
    overflow: hidden;
}

#window2 {
    position: fixed;
    top: 50%;
    left: 50%;
    overflow:auto;
    transform: translate(-50%, -50%);
    z-index: 9000;
    background-color: #eeeeee;
    width: 60%;
    max-width:1500px;
    min-width:600px;
    border-radius: 1.5rem;
    box-shadow: 0 1rem 2rem rgb(0 0 0 / 10%);
    display: none;
    max-height:95vh;
    overflow: hidden;
}
#window3 {
    position: fixed;
    top: 50%;
    left: 50%;
    overflow:auto;
    transform: translate(-50%, -50%);
    z-index: 9000;
    background-color: #eeeeee;
    width: 60%;
    max-width:1500px;
    min-width:600px;
    border-radius: 1.5rem;
    box-shadow: 0 1rem 2rem rgb(0 0 0 / 10%);
    display: none;
    max-height:95vh;
    overflow: hidden;
}


#window5 {
    position: fixed;
    left: 60%;
    top: 50%;
    transform-origin: top center;
    transform: translate(-10%, 0);
    z-index: 9000;
    background-color: #fff;
    width: 500px;
    border-radius: 1.5rem;
    box-shadow: 0 1rem 2rem rgb(0 0 0 / 10%);
    display: none;
}

[id^="closeButton"] {
    color: #ffffff;
    cursor: pointer;
    position: relative;
    font-size: 24px;
}

.window__title {
    font-size:14px;
    text-transform: uppercase;
    padding: 10px 20px;
	z-index: 10;
	background-color: $color-blue-1;
	color: $color-white;
	border-radius: 1.5rem 1.5rem 0 0;
	justify-content: space-between;
    align-items: center;
	display: flex;
	touch-action: none;
    font-weight:600;
    cursor:move;
    cursor: grab;

    &--yellow  {
        background-color: $color-yellow-1;
        color:$color-black-1;
    }
    &--yellow b {
        color:$color-black-1;
    }

    &--orange  {
        background-color: $color-orange;
        color: $color-white;
    }
    &--blue  {
        background-color: $color-blue-3;
        color: $color-white;
    }
}


.window--subhead {
    padding:15px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--gray {
        background-color:$color-grey-light-1;
    }
    &--yellow {
        background-color:$color-yellow-2;
    }
    &--orange {
        padding:10px;
        background-color:$color-orange;
        color:$color-black-1
    }

    &--center {
        justify-content: center;
    }
}

.window__footer {
    padding:10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size:1.5rem;

    &__button {
        cursor:pointer;
        border:1px solid $color-grey-light-4;
        padding:4px 10px;
        font-weight:600;
        border-radius:5px;

        &--white {
            background-color:#fff;
        }
    }

    &__button:hover {
        box-shadow: 0 1rem 1rem rgb(0 0 0 / 5%);
    }

    &--gray {
        background-color:$color-grey-light-1;
        border-bottom-left-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
    }


    &__button i {
        color:$color-blue-1;
    }
}


.windowActive {
	z-index: 10000;
}

.mainWindow {
    border: 20px solid white;
    overflow: hidden;
    border-radius: 0 0 1.5rem 1.5rem;
}
.nakijken-antwoorden {
    overflow-y:auto;
    max-height:450px;
    padding-right:10px;
}


.small {
	width: 600px;
}

.large {
	width: 1000px;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}
